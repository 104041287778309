import React from 'react';
import Divider from '../../components/Divider';

const Join: React.FC = () => {
  return (
    <>
        <div id="page-join" className="page-join">
            <Divider text="COLABORE"/>
            <div id="page-join-1" >
                <div className="container d-flex h-100">
                    <div className="row m-0 justify-content-center align-self-center">
                        <div className="col-12">
                            <article>
                                <p>AJUDE A GARANTIR ALIMENTAÇÃO E INSUMOS DE NECESSIDADES BÁSICAS DURANTE A CRISE DO COVID-19 
                                <b> doando </b> para as ADOLESCENTES EGRESSAS DO SOCIOEDUCATIVO EM SITUAÇÃO 
                                    DE EXTREMA VULNERABILIDADE SOCIOECONÔMICA.
                                </p> 
                            </article>
                        </div>
                    </div>
                </div>
            </div>

            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <h1 className="display-2">O que está acontecendo com adolescentes egressas do sistema socioeducativo?</h1>
                <p className="lead">Adolescentes egressas do socioeducativo tem entrado em contato conosco para relatar que estão tendo  dificuldades  em acessar alimento e produtos essenciais durante a crise do COVID-19.</p>
              </div>
            </div>

            <div className="page-join">
                <div className="capa-background-join">
                    <div className="row h-100 m-0">                
                        <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12 overlay-join m-0">
                            <div className="container d-flex h-100">
                                <div className="row justify-content-center p-3">
                                    <div className="col align-self-center">
                                        <h1 className="display-3">O que a Coletiva Teia está fazendo ?</h1>
                                        <p>A Coletiva Teia está realizando uma Ação Direta Urgente para o provimento de alimentação, materiais de limpeza e higiene pessoal, fraldas e outros materiais de necessidade para o cuidado com adolescentes egressas do socioeducativo, principalmente aquelas que têm bebês. Dessa forma, as adolescentes que necessitam deste atendimento emergencial  que já estão em contato conosco a partir do Projeto Social, que já desenvolvemos em uma Unidade Socioeducativa Feminina em Belo Horizonte, estão recebendo o auxílio de forma direta, a partir do acolhimento das demandas específicas.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col m-0 p-0"></div>
                    </div>
                </div>
            </div>

            <div className="page-join">
                <div className="capa-background-join-2">
                    <div className="row h-100 m-0">
                        <div className="col d-none d-lg-block"></div>
                        <div className="col white-overlay-join">
                            <div className="container d-flex h-100">
                                <div className="row justify-content-center p-3">
                                    <div className="col align-self-center">
                                        <h1 className="display-3">E qual a importância desta ação?</h1>
                                        <p>A relevância da implementação do projeto se dá pela necessidade básica do público alvo de acessar tais provimentos, uma vez que são essenciais para a existência da vida humana, bem como, para a apropriada prevenção contra o COVID-19. A urgência da implementação do projeto neste momento se dá pela impossibilidade do público alvo de desenvolver as diversas atividades informais que necessitam para conseguir o provimento material de suas necessidades básicas para si e para os seus bebês.</p>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="jumbotron jumbotron-fluid jumbotron-dark">
              <div className="container">
                <h1 className="display-2">COLABORE NA MANUTENÇÃO DO COLETIVO </h1>
                <p className="lead">O que está acontecendo com a Coletiva Teia e qual a importância da sua doação?</p>
              </div>
            </div>

            <div id="page-join-4" className="page-join">
                <div className="capa-background-join-3">
                    <div className="row h-100 m-0">
                        <div className="col overlay-join gold-overlay-join m-0">
                            <div className="container d-flex h-100">
                                <div className="row justify-content-center">
                                    <div className="col align-self-center">
                                        <h1 className="display-3">Estamos na luta</h1>
                                        <p>A Coletiva Teia continua mantendo suas ações de ativismo em meio a crise do COVID-19. No momento, atuamos  principalmente no Projeto de Ação Urgente para provimento de alimentação e produtos essenciais para prevenção do alastramento do COVID-19, bem como para a manutenção da vida apoiando as adolescentes em vulnerabilidade extrema.</p>
                                        <p>Entretanto, a Coletiva TEIA corre risco de esgotamento de recursos, uma vez que não possui um fundo emergencial para a manutenção de suas atividades, que foram prejudicadas pela crise do COVID-19. </p>
                                        <p>Para colaborar com a manutenção estrutural do coletivo, bem como de nosso ativismo, clique aqui [LINKS DE COLABORAÇÃO].</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col d-none d-lg-block"></div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default Join;